import { useEffect, useState } from 'react'
import Web3 from 'web3';
import BigNumberJs from 'bignumber.js'
import { useMulticallContract,useSafeRouterContract } from './useContract'
import ERC20_INTERFACE from '../constants/abis/erc20'
import ROUTER_INTERFACE from '../constants/abis/router'
import priceContracts from '../constants/PixiuPriceContracts'


type ApiResponse = {
  prices: {
    [key: string]: string
  }
  update_at: string
}

/**
 * Due to Cors the api was forked and a proxy was created
 * @see https://github.com/pancakeswap/gatsby-pancake-api/commit/e811b67a43ccc41edd4a0fa1ee704b2f510aa0ba
 */
const api = 'https://api.pancakeswap.com/api/v1/price'

const useGetPriceData = () => {
  const [data, setData] = useState<number>(0)


  const routerConctract = useSafeRouterContract();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(routerConctract){
          const web3 = new Web3("https://bsc-dataseed1.binance.org");
          const {cakeAddress, bnbAddress,usdAddress, lpAddress,routerAddress} = priceContracts;
          const res = await routerConctract.getAmountsOut("1000000000000000000", [bnbAddress ,usdAddress]);
          const bnbPrice =  web3.utils.fromWei(res[1].toString());          
          const resToken = await routerConctract.getAmountsOut("1000000000000000000", [cakeAddress ,bnbAddress]);
          const tokenPrice = web3.utils.fromWei(resToken[1].toString());
          const USDPrice = (new BigNumberJs(tokenPrice).multipliedBy(bnbPrice)).toNumber();
          setData(USDPrice)

        }
      } catch (error) {
        console.error('Unable to fetch price data:', error)
      }
    }

    fetchData()
  }, [setData,routerConctract])

  return data
}

export default useGetPriceData
