import { MenuEntry } from '@pancakeswap-libs/uikit'

export const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://potluckprotocol.com',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://trade.potluckprotocol.com/#/swap',
      },
      {
        label: 'Liquidity',
        href: 'https://trade.potluckprotocol.com/#/pool',
      },
    ],
  },
  {
    label: 'The Cave',
    icon: 'CaveIcon',
    href: 'https://potluckprotocol.com/cave',
  },
  {
    label: 'The Attic',
    icon: 'AtticIcon',
    href: 'https://potluckprotocol.com/attic',
  },
  {
    label: 'The Bat Box',
    icon: 'BatboxIcon',
    href: 'https://potluckprotocol.com/batbox',
  },
  {
    label: 'Referrals',
    icon: 'ReferralIcon',
    href: 'https://potluckprotocol.com/referrals',
  },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Twitter',
        href: 'https://twitter.com/PotluckProtocol',
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Medium',
        href: 'https://medium.com/@potluckprotocol',
      },
    ],
  },
  {
    label: 'Slasher',
    icon: 'HamburgerIcon',
    href: 'https://slasherbsc.com/',
  },
  {
    label: 'Sonar (coming soon)',
    icon: 'HamburgerCloseIcon',
    href: 'https://potluckprotocol.com/#/',
  }
]

export const socials = [
  {
    label: "Telegram",
    icon: "TelegramIcon",
    items: [
      {
        label: "Channel",
        href: "https://t.me/PTLKBATS",
      },
      {
        label: "Group",
        href:"https://t.me/PTLKCHATS"
      }
    ],
  },
  {
    label: "Twitter",
    icon: "TwitterIcon",
    href: "https://twitter.com/PotluckProtocol",
  },
  {
    label: "Reddit",
    icon: "RedditIcon",
    href: "https://reddit.com/r/potluckprotocol",
  },
  {
    label: "Medium",
    icon: "MediumIcon",
    href: "https://potluckprotocol.medium.com",
  }
];

export default config
